import { ROUTE_NOTIFY, ROUTE_UNKNOWN, ROUTE_SHARE } from "../../../../defaults/routes";

export const LSK__STEP = 'unico-idpay-step';
export const LSK__CPF = 'unico-idpay-cpf';
export const LSK__NAME = 'unico-idpay-name';
export const LSK__TOKEN = 'unico-idpay-token';
export const LSK__CURRENT_ROUTE = 'unico-idpay-current-route';

/* HOF */

export const clearLocalStorageOfOldRoutes = (transactionId, route) => {
  const routeList = [
    ROUTE_NOTIFY,
    ROUTE_UNKNOWN,
    ROUTE_SHARE,
  ];

  const keyList = [
    LSK__STEP,
    LSK__CPF,
    LSK__NAME
  ];

  routeList.forEach(item => {
    if (route !== item) {
      keyList.forEach(key => {
        try {
          localStorage?.removeItem?.(getKeyByRoute(key, transactionId, item));
        } catch (e) { /* */ }
      });
    }
  });
}

export const getKeyByRoute = (key, transactionId, route) => {
  return `${key}__${route}__${transactionId}`;
}

export const getKey = (key, transactionId) => {
  return `${key}__${transactionId}`;
}

export const getKeyCurrentRoute = (key, transactionId, includeRoute) => {
  if (includeRoute) {
    const route = window.location.pathname;
    clearLocalStorageOfOldRoutes(transactionId, route);
    return getKeyByRoute(key, transactionId, route);
  } else {
    return getKey(key, transactionId);
  }
}

export const getFromLocalStorage = (key, includeRoute) => (transactionId) => {
  try {
    if (transactionId) {
      return localStorage?.getItem?.(getKeyCurrentRoute(key, transactionId, includeRoute));
    }
    return null;
  }
  catch (e) {/* */ }
};

export const updateInLocalStorage = (key, includeRoute) => (transactionId, value) => {
  try {
    if (transactionId) {
      localStorage?.setItem?.(getKeyCurrentRoute(key, transactionId, includeRoute), value);
    }
  }
  catch (e) {/* */ }
};

/* LS Functions */

export const updateStepInLocalStorage = updateInLocalStorage(LSK__STEP, true);
export const updateCPFInLocalStorage = updateInLocalStorage(LSK__CPF, true);
export const updateNameInLocalStorage = updateInLocalStorage(LSK__NAME, true);
export const updateCallbackRouteInLocalStorage = updateInLocalStorage(LSK__CURRENT_ROUTE, false);
export const updateTokenInLocalStorage = updateInLocalStorage(LSK__TOKEN, false);

export const getStepFromLocalStorage = getFromLocalStorage(LSK__STEP, true);
export const getCPFFromLocalStorage = getFromLocalStorage(LSK__CPF, true);
export const getNameFromLocalStorage = getFromLocalStorage(LSK__NAME, true);
export const getCallbackRouteFromLocalStorage = getFromLocalStorage(LSK__CURRENT_ROUTE, false);
export const getTokenFromLocalStorage = getFromLocalStorage(LSK__TOKEN, false);


